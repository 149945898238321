import { useState } from 'react';
import './App.css';

const STEPS = {
    ENTER_USERNAME: 0,
    READY: 1,
    SEARCHING: 2,
    MATCH_FOUND: 3
}

function sleep(milliseconds) {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, milliseconds);
    });
}

function startSleeping(justSlept) {
    sleep(5000)
        .finally(() => {justSlept(true)});
}

async function findMatch(username, callback, setStep = () => {}, setError = () => {}) {
    if (!username) {
        setStep(STEPS.ENTER_USERNAME);
        setError('USERNAME IS INVALID');
        return;
    }

    setStep(STEPS.SEARCHING);

    let maxAttempts = 15;

    let result = '';
    let success = false;
    while (maxAttempts-- > 0 && !result) {
        result = await tryMatch(username)
            .then((response) => {
                if (!response.ok) {
                    console.log('Failed first attempt');
                }
                success = response.status === 200;

                return response.text();
            })
            .then(async (matchUsername) => {
                if (!success) {
                    setError(Array(15 - maxAttempts).fill('.').join(''))
                    await sleep(1000);
                    return '';
                }

                console.log(matchUsername);
                return matchUsername;
            })
            .catch((error) => {
                console.log(error);
                success = false;

                return 'break';
            });
    }

    if (result && success) {
        callback(result);
    } else {
        setError('Max attempts made. Please retry.');
        setStep(STEPS.READY);
    }


    console.log(result, success);
}

function tryMatch(username) {
    return new Promise((resolve, reject) => {
        fetch(`/api/findMatch?username=${encodeURIComponent(username)}`)
            .then(resolve, reject)
            .catch(reject);
    });
}

function App() {
    const [step, setStep] = useState(STEPS.ENTER_USERNAME);
    const [username, setUsername] = useState('');
    const [matchUsername, setMatchUsername] = useState('');
    const [error, setError] = useState('');
    const [hasSlept, justSlept] = useState(false);

    // Load the cookies to see if the username was already entered.
    // @TODO


    return (
            <div className="App">
                <header className="App-header">
                    {error && (
                        <div className="error">
                            <p>{error}</p>
                        </div>
                    )}
                    {step === STEPS.ENTER_USERNAME && (
                        <>
                            <form onSubmit={(event) => {
                                event.preventDefault();
                                // Validate that the username is a valid format.
                                console.log(username, username.match(/^[a-zA-Z0-9_. ]+#[0-9]+$/));
                                if (!username || !username.match(/^[a-zA-Z0-9_. ]+#[0-9]+$/)) {
                                    setError('USERNAME IS INVALID');
                                    return;
                                }

                                // Change states
                                setStep(STEPS.READY);
                            }}>
                                <label htmlFor="username">Enter Username</label><input name="username" value={username} type="text" autoFocus="autofocus" onChange={(event) => {
                                    setUsername(event.target.value);
                                }}></input>
                                <button type="submit">
                                    Submit
                                </button>
                            </form>
                        </>
                    )}
                    {step === STEPS.READY && (
                        <>
                            <p>Your username is <b>{username}</b></p>
                            <form onSubmit={() => {
                                    // Kick off the network request to find a result. Set timeout to indefinite.
                                    findMatch(username, (matchedUsername) => {
                                        setMatchUsername(matchedUsername);
                                        setError('');
                                        setStep(STEPS.MATCH_FOUND);
                                        justSlept(false);
                                    }, setStep, setError);
                                }}>
                                <button onClick={() => {
                                    setStep(step - 1);
                                    setError('');
                                }}>Back</button>
                                <button type="submit" autoFocus="autofocus">Find player</button>
                            </form>
                        </>
                    )}
                    {step === STEPS.SEARCHING && (
                        <>
                            <h2>SEARCHING</h2>
                            <button type="submit" onClick={() => {
                                setStep(step - 1);
                            }}>Back</button>
                        </>
                    )}
                    {step === STEPS.MATCH_FOUND && (
                        <>
                            <h2>MATCH FOUND</h2>
                            <p>Please start a match with</p>
                            <h4>{matchUsername}</h4>
                            {hasSlept && (
                                <button autoFocus="autofocus" type="submit" onClick={() => {
                                    setStep(STEPS.READY);
                                }}>Done!</button>
                            )}
                            {!hasSlept && startSleeping(justSlept) && (<></>)}
                        </>
                    )}
                </header>
            </div>
        );
    }

    export default App;
